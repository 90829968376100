import * as React from "react";
import {graphql} from 'gatsby'
import Layout from '../components/layout';
import Header from '../components/header';
import Hero from "../components/hero";
import HomeArtists from "../components/homeartists";
import Contactform from '../components/contactform'
import {StaticImage} from "gatsby-plugin-image";
import Mouse from "../assets/Mouse.svg"
import Arrow from '../assets/Arrow1.svg';
import Play from "../assets/Play.svg"
import scrollTo from 'gatsby-plugin-smoothscroll';
import {Link} from "../../.cache/gatsby-browser-entry";
import {Helmet} from "react-helmet";

const IndexPage = () => {
    return (
        <Layout>

            <Helmet>
                <title>DJ / Konferansjer - Piotr Szymański - Wesela / Eventy</title>
                <meta name="description" content="Stwórz rustykalne / boho wesele swoich marzeń, uzyskując niepowtarzalny klimat dzięki wyjątkowej oprawie muzyczno-konferansjerskiej." />
                <meta http-equiv="Content-type" content="text/html;charset=UTF-8" />
            </Helmet>

            <Hero className="home">
                <StaticImage
                    src='../images/hero-home.png'
                    alt="A dinosaur"
                    placeholder="blurred"
                    layout="fullWidth"
                    className="hero"
                />
                <Header pageName="home"/>
                <div className="hero-title home">
                    <h1>DJ na<br/>wesele</h1>
                    <h3 className="line-before">w każdym stylu</h3>
                    <div className="more-wrapper">
                        <button className="line-after" onClick={() => scrollTo('#about')}><Mouse fill="#C2A353"/> <span>Dowiedz się więcej</span>
                        </button>
                    </div>
                </div>

            </Hero>
            <section id="about" className="home-about">
                <div className="about-img">
                    <StaticImage
                        src='../images/dj-piotr-szymanski.png'
                        alt="DJ na wesele - Warszawa"
                        placeholder="blurred"
                        layout="fullWidth"
                        className="home-about-img"
                    />
                </div>
                <div className="about-text">
                    <h2>Poznajmy się</h2>
                    <Arrow className="arrow-down icon-white arrow-sub-title"/>
                    <p>
                        Stwórz wesele swoich marzeń, uzyskując niepowtarzalny klimat dzięki wyjątkowej oprawie muzycznej oraz technicznej. Oprawa muzyczna na ważne uroczystości zawsze powinna zostać wybrana starannie i z dbałością o szczegóły.  Od wielu lat zajmuję się prowadzeniem eventów oraz wesel. Zdobyte doświadczenie oraz znajomość najnowszych trendów pozwala mi stanąć na wysokości zadania i być zawsze przygotowanym na każdą okazję. Uwielbiam tworzyć świetną atmosferę, jestem kreatywny i bardzo szybko nawiązuję kontakt z publicznością. Do każdego zlecenia podchodzę indywidualnie, dostosowując ofertę do określonego programu wesela.
                    </p>
                    <p>
                        Z moich usług można skorzystać na terenie całej Polski. Odpowiednio dobrana oprawa muzyczna ma sprawić nie tylko radość Parze Młodej, ale również gościom weselnym.  Jeśli planujesz wesele i potrzebujesz mojego wsparcia, chcąc wznieść swoją uroczystość na najwyższy poziom, zapraszam Cię do kontaktu! Jako DJ na wesele z Warszawa oraz konferansjer zapewniam niezapomnianą zabawę gościom.
                    </p>
                </div>
            </section>
            <section id="wedding" className="home-about">
                <div className="about-text">
                    <h2>Wesele marzeń z DJ'em</h2>
                    <Arrow className="arrow-down icon-white arrow-sub-title"/>
                    <p>
                        Zaplanuj swoje wymarzone wesele razem ze mną!
                    </p>
                    <p>
                        Kto z nas nie marzy o tym, by jego wesele było spokojne i udane? Jako profesjonalny DJ warszawa pomogę Ci wybrać odpowiedni dla Ciebie repertuar muzyczny.
                    </p>
                    <p>
                        Kilkanaście lat doświadczenia sprawia, że podchodzę do każdego klienta indywidualnie, poznając jego potrzeby oraz oczekiwania. Swoją pracę wykonuję z entuzjazmem i gwarantuję pełne zaangażowanie. Oferuję zarówno oprawę muzyczną, nagłośnienie wesel, jak i usługi konferansjera na wesele w Warszawie i okolicach. Muzyka to podczas wesela jeden z najważniejszych elementów, to właśnie od niej zależy, czy goście będą dobrze się bawić, dlatego dobry DJ na wesele mazowieckie umiejący porwać tłum, to sposób na udaną zabawę do białego rana!
                    </p>
                    <Link to={"/oferta"} title={"Oferta"}><Arrow/> <span>sprawdź ofertę</span></Link>
                </div>
                <div className="about-img">
                    <StaticImage
                        src='../images/home-02.png'
                            alt="DJ na wesele - Piotr Szymański"
                        placeholder="blurred"
                        layout="fullWidth"
                        className="home-about-img"
                    />
                </div>
            </section>
            <section id="films" className="films-wrapper">
                <div className="film">
                    <StaticImage
                        src='../images/home-film.png'
                        alt="film z imprezy"
                        placeholder="blurred"
                        layout="fullWidth"

                    />
                </div>
                <div className="films-desc">
                    <div className="films-title">
                        <h2>Zobacz <br/>jak pracujemy</h2>
                        <h3 className="line-before">film z imprezy</h3>
                    </div>
                    <Link to={"/galeria"} className="play-film line-after">
                        <Play className="icon-gold"/>
                        <span>Sprawdź galerię</span>
                    </Link>
                </div>
            </section>
            <section id="artists" className="artists">
                <HomeArtists/>
                <div className="artists-title">
                    <h2>Artyści</h2>
                </div>
                <Link to={"artysci"} className="section-more"><Arrow className="icon-white"/> <span className='sub-title'>poznaj naszych artystów</span></Link>

            </section>

            <Contactform/>
        </Layout>
    )
}

export default IndexPage


export const HomePage = graphql`
  query {
    fileName: file(relativePath: { eq: "images/hero-home.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
